import React, {useContext} from "react";
import { graphql } from 'gatsby';
import HtmlBlock from "../components/htmlBlock/htmlBlock";
import Link from "../components/link/link";
import { sitemap } from './scssModules/sitemap.module.scss';
import { LangContext } from '../context/LocaleContext';

const SiteMap = ({data}) => {
  const { getTranslation } = useContext(LangContext);

  const {megaMenuLinks} = data?.datoCmsGlobalHeader || {};

  return (
    <div className={`container page ${sitemap}`}>
        <HtmlBlock tag={'h1'}>{getTranslation('SiteMap')}</HtmlBlock>
        <hr />
       {
          megaMenuLinks?.map((section, i) => 
            <section key={i}>
            {
              section.linkName && 
              <HtmlBlock tag={'h2'} className={'h6'} data={section.linkName}/>            
            }
            {
              section.col2Content.map((subSection, i)=>{
                let links = subSection.links || subSection.linkS || null;

                return (
                  <div key={i}>
                    {
                      subSection.heading && <HtmlBlock className={'p1'} tag={'h3'}  data={subSection.heading}/>            
                    }
                    <ul>
                      {
                        links && links.map((link)=>
                          <li key={i}>
                            <Link className="" icon={false} data={link} /> 
                          </li>                
                        )
                      }
                      {
                        subSection.featuredLink && 
                        <li key={i}>
                          <Link className="" icon={false} data={subSection.featuredLink[0]} /> 
                        </li> 
                      }
                    </ul>
                  </div>
                )
              }
              )
            }
            </section>
          )
        }
    </div>
  )
}

export default SiteMap;

export const query = graphql`
  query($locale : String!) {
    datoCmsGlobalHeader(locale: {eq: $locale}){
      megaMenuLinks{
        ...MegaMenuItemFragment
      }
    }
  }`